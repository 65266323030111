/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, css, LitElement } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import applyBrandingsSettings from './utils/brandingUtils.js';
import './kiwi-launch.js';

let apiUrl = 'https://checkout-frontpage-api.test.kiwilaunch.com';

// eslint-disable-next-line no-undef
if (__environment__ === 'preprod') {
  apiUrl = 'https://checkout-frontpage-api.preprod.kiwilaunch.com';
  // eslint-disable-next-line no-undef
} else if (__environment__ === 'prod') {
  apiUrl = 'https://checkout-frontpage-api.kiwilaunch.com';
}
class KiwiCatalog extends LitElement {
  static get tag() {
    return `kiwi-catalog`;
  }

  static get properties() {
    return {
      catalogId: { type: String },
      catalogSlug: { type: String },
      view: { type: String },
      data: Object,
      selectedService: Object,
      page: { type: Number },
      searchText: { type: String },
      servicePerPage: { type: Number },
      brandingSettings: Object,
      queryParams: Object,
      visibleReviewCount: { type: Number },
    };
  }

  constructor() {
    super();

    this.isLoaded = false;
    this.data = null;
    this.selectedService = null;
    this.page = 0;
    this.searchText = null;
    this.servicePerPage = 5;
    this.brandingSettings = null;
    this.queryParams = null;
    this.visibleReviewCount = 4;
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        width: 100%;
        max-width: 1200px;
        font-family: var(--kiwi-font-family) !important;
        margin: auto;
      }
      :host * {
        box-sizing: border-box !important;
      }
      .contentHolder {
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .catalogHolder {
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
      .catalogTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.01em;
        color: #191d23;
        margin: 0;
      }
      .catalogDetail {
        display: flex;
        gap: 20px;
      }
      .catalogImage {
        width: 347px;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .catalogTextHolder {
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
      }
      .catalogStaticTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: #191d23;
      }
      .catalogDescription {
        word-break: break-word;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #525a66;
      }
      .serviceSearchHolder {
        position: relative;
      }
      .searchIcon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        margin: auto;
      }
      .serviceSearchInput {
        height: 100%;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
        padding: 12px 24px 12px 39px;
        outline: none !important;
        display: flex;
        width: 50%;
        border: 1px solid #b8c0cc;
        gap: 12px;
        height: 44px;
        color: #525a66;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .serviceSearchInput::placeholder {
        color: #b8c0cc;
      }
      .serviceSearchInput:focus {
        outline: none;
        border-color: var(--kiwi-main-color, #377a4e);
      }
      .catalogServices {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .catalogServiceItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        gap: 20px;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .catalogServiceItemInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        flex: 1;
      }
      .serviceImageHolder {
        width: 100px;
      }
      .serviceImageHolder img {
        width: 100%;
        object-fit: contain;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .serviceShortInfo {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .nextAvailability,
      .noUpcomingSchedules {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: var(--kiwi-secondary-color, #377a4e);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .nextAvailabilityDivider {
        border: 2px solid var(--kiwi-secondary-color, #377a4e);
        border-radius: 50%;
      }
      .serviceTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #525a66;
      }
      .servicePriceInfo {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
      }
      .servicePrice {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: #525a66;
      }
      .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border: none;
        border-radius: var(--kiwi-button-radius, 4px);
        cursor: pointer;
        padding: 12px 24px;
        height: 42px;
        background: var(--kiwi-main-color, #377a4e);
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: var(--kiwi-text-color, #fff) !important;
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
      }
      .pageItem {
        cursor: pointer;
        width: 20px;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #b8c0cc;
      }

      .pageItem:hover {
        color: #525a66;
      }
      .pageItem.activePage {
        color: #191d23;
      }

      .paginationIconHolder {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .paginationIconHolder.disabled {
        cursor: not-allowed;
      }

      .paginationIconHolder.disabled path {
        fill: #b8c0cc !important;
      }

      .paginationIcon path {
        fill: #191d23;
      }

      .serviceHolder {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      .goBack {
        cursor: pointer;
        color: var(--kiwi-main-color, #377a4e);
        display: inline-flex;
        gap: 15px;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
      }

      .goBack path {
        fill: var(--kiwi-main-color, #377a4e);
      }

      .servicePageTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.01em;
        color: #191d23;
        margin: 8px 0 40px;
      }
      .serviceContentHolder {
        display: flex;
        gap: 20px;
      }
      .serviceInfo {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .serviceImage {
        text-align: center;
      }
      .serviceImage img {
        width: 100%;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .serviceHeading {
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: #191d23;
        font-style: normal;
      }
      .serviceDescription {
        word-break: break-word;
        color: #525a66;
        line-height: 140%;
      }
      .serviceReviewData {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .reviewsHeading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .reviewAverageHolder {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;
      }

      .reviewAverageStars {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
      .reviewCount {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #525a66;
      }
      .reviewAverageText {
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: #525a66;
        font-size: 16px;
      }
      .reviewAverageText span {
        color: #191d23;
        font-size: 19px;
      }
      .reviewItems {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .reviewItem {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-bottom: 12px;
        border-bottom: 1px solid #393d40;
      }
      .reviewItemHeading {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
      }

      .reviewItemStarsHolder {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }

      .reviewItemStars svg {
        width: 16px;
        height: 16px;
      }

      .reviewItemAuthor {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #191d23;
      }

      .reviewItemDate {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #525a66;
        opacity: 0.5;
      }
      .reviewItemComment {
        display: flex;
        flex-direction: column;
        word-break: break-word;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        font-size: 16px;
        letter-spacing: -0.01em;
      }

      .reviewLoadMore {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: var(--kiwi-secondary-color, #377a4e);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 12px;
        border: 1px solid var(--kiwi-secondary-color, #377a4e);
        text-decoration: none;
        border-radius: var(--kiwi-button-radius, 4px);
        width: fit-content;
        align-self: center;
        cursor: pointer;
      }

      .serviceLocationHolder {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .serviceMapHolder {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .serviceMapHolder img {
        width: 100%;
        border-radius: var(--kiwi-border-radius, 4px);
      }
      .serviceMapText {
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #525a66;
      }

      .desktopWidgetHolder {
        width: 100%;
        align-self: center;
      }
      .mobileWidgetHolder {
        display: none;
        width: 100%;
        align-self: center;
      }

      .contactUsHolder {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
      }
      .contactUsTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: #191d23;
      }
      .contactUsButtonHolder {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .contactUsButton {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: var(--kiwi-main-color, #377a4e);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 12px;
        border: 1px solid var(--kiwi-main-color, #377a4e);
        text-decoration: none;
        border-radius: var(--kiwi-button-radius, 4px);
      }

      .contactUsButton svg {
        width: 16px;
        height: 16px;
      }
      .contactUsButton svg path {
        fill: var(--kiwi-main-color, #377a4e);
      }

      .gridViewServices {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      .gridViewServices .catalogServiceItem {
        flex-direction: column;
        border: 1px solid #b8c0cc;
        padding: 12px;
      }
      .gridViewServices .catalogServiceItemInfo {
        flex-direction: column;
        width: 100%;
      }
      .gridViewServices .serviceImageHolder {
        height: 100%;
        width: auto;
      }
      .gridViewServices .serviceImageHolder img {
        max-width: 100%;
      }
      .gridViewServices .servicePriceInfo {
        justify-content: space-between;
        width: 100%;
      }
      .gridViewServices .serviceShortInfo {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        .catalogHolder {
          gap: 20px;
        }
        .catalogImage {
          width: 100%;
        }
        .catalogDetail {
          flex-direction: column;
        }
        .catalogTextHolder {
          gap: 12px;
        }
        .serviceSearchHolder {
          width: 100%;
        }
        .catalogServices {
          gap: 12px;
        }

        .serviceSearchInput {
          width: 100%;
        }
        .servicePageTitle {
          margin-bottom: 20px;
        }

        .serviceContentHolder {
          flex-direction: column;
        }

        .desktopWidgetHolder {
          display: none;
        }

        .mobileWidgetHolder {
          display: block;
        }
      }

      @media screen and (min-device-width: 500px) and (max-width: 768px) {
        .gridViewServices {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      @media screen and (min-device-width: 769px) and (max-width: 1024px) {
        .desktopWidgetHolder {
          width: 360px;
        }
      }

      @media screen and (max-width: 400px) {
        .mobileWidgetHolder {
          width: 100%;
        }
      }
      @media screen and (max-width: 575px) {
        .catalogServiceItemInfo {
          flex-direction: column;
          align-items: flex-start;
        }
        .gridViewServices {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .gridViewServices .serviceImageHolder {
          width: 100%;
          height: auto;
        }
        .servicePriceInfo {
          justify-content: space-between;
          width: 100%;
          gap: 8px;
        }
        .catalogServiceItem {
          gap: 12px;
        }
        .serviceImageHolder {
          flex: 1;
        }
        .nextAvailability {
          font-size: 10px;
        }
        .serviceTitle {
          font-size: 11px;
        }
        .button {
          height: 31px;
          font-size: 11px;
          padding: 12px 8px;
        }
      }
      @media screen and (min-width: 769px) {
        .desktopWidgetHolder {
          width: 400px;
          align-self: flex-start;
        }
      }
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchData();
  }

  updated() {
    this.servicePerPage = this.view === 'GRID_VIEW' ? 6 : 5;
  }

  fetchData() {
    let requestUrl = `${apiUrl}/Catalogs/Default`;

    if (this.catalogId) {
      requestUrl = `${apiUrl}/Catalogs/${this.catalogId}`;
    }

    if (this.catalogSlug) {
      requestUrl = `${apiUrl}/Catalogs/Slug/${this.catalogSlug}`;
    }

    fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic a2l3aTpub3RhZnJ1aXQ=',
      },
    })
      .then(response => response.json())
      .then(response => {
        this.data = response?.data;
        if (this.brandingSettings) {
          applyBrandingsSettings({
            ...this.data?.brandingSettings,
            ...JSON.parse(this.brandingSettings),
          });
        } else {
          applyBrandingsSettings(this.data?.brandingSettings);
        }
        this.isLoaded = true;
      })
      .catch(() => {});
  }

  selectService(service = null) {
    let element = document.querySelectorAll(`[catalogId="${this.catalogId}"]`);
    if (!element?.length === 0) {
      element = document.querySelectorAll(
        `[catalogSlug="${this.catalogSlug}"]`
      );
    }

    if (element?.[0]) {
      element?.[0]?.scrollIntoView({ behavior: 'smooth' });
    }

    this.selectedService = service;

    this.searchText = '';
  }

  getServices() {
    let data = [...this.data?.services];
    if (this.searchText) {
      data = data.filter(x =>
        x.title?.toLowerCase().includes(this.searchText?.toLowerCase())
      );
      if (this.page) {
        this.page = 0;
      }
    }
    return data;
  }

  onLoadMoreClick() {
    this.visibleReviewCount += 10;
  }

  getReviews() {
    return this.data?.reviewsView?.reviews.slice(0, this.visibleReviewCount);
  }

  render() {
    if (!this.isLoaded || !this.data) {
      return html``;
    }

    const services = this.getServices();
    const reviews = this.getReviews();

    return html` <div class="contentHolder">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      ${this.selectedService
        ? html`<div class="serviceHolder">
            <div>
              <div class="goBack" @click="${() => this.selectService()}">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8332 6.16667H3.5249L7.59157 2.1C7.91657 1.775 7.91657 1.24167 7.59157 0.916666C7.26657 0.591666 6.74157 0.591666 6.41657 0.916666L0.924902 6.40833C0.599902 6.73333 0.599902 7.25833 0.924902 7.58333L6.41657 13.075C6.74157 13.4 7.26657 13.4 7.59157 13.075C7.91657 12.75 7.91657 12.225 7.59157 11.9L3.5249 7.83333H12.8332C13.2916 7.83333 13.6666 7.45833 13.6666 7C13.6666 6.54167 13.2916 6.16667 12.8332 6.16667Z"
                    fill="#377A4E"
                  />
                </svg>
                ${this.data?.title}
              </div>
            </div>
            <h1 class="servicePageTitle">${this.selectedService?.title}</h1>
            <div class="serviceContentHolder">
              <div class="serviceInfo">
                <div class="serviceImage">
                  <img
                    src=${this.selectedService?.mediaList?.[0]?.image}
                    alt=${this.selectedService?.title}
                  />
                </div>
                ${this.selectedService?.description
                  ? html` <div class="serviceHeading">
                        ${this.data?.locale?.Funnel?.Description}
                      </div>
                      <div class="serviceDescription">
                        ${unsafeHTML(this.selectedService?.description)}
                      </div>`
                  : ''}

                <div class="mobileWidgetHolder">
                  <kiwi-launch
                    serviceId=${this.selectedService?.serviceId}
                    brandingSettings=${this.brandingSettings}
                    queryParams=${this.queryParams}
                  ></kiwi-launch>
                </div>
                ${this.selectedService?.serviceAddressView?.addressLine1
                  ? html`<div class="serviceLocationHolder">
                      <div class="serviceHeading">
                        ${this.data?.locale?.Funnel?.Location}
                      </div>

                      <div class="serviceMapHolder">
                        <div class="serviceMapText">
                          ${this.selectedService?.serviceAddressView
                            ?.addressLine1 &&
                          html`<div>
                            ${this.selectedService?.serviceAddressView
                              ?.addressLine1 === 'To be provided online'
                              ? this.data?.locale?.Funnel?.ToBeProvidedOnline
                              : this.selectedService?.serviceAddressView
                                  ?.addressLine1}
                          </div>`}
                          ${this.selectedService?.serviceAddressView
                            ?.addressLine2 &&
                          html`<div>
                            ${this.selectedService?.serviceAddressView
                              ?.addressLine2}
                          </div>`}
                          <div>
                            ${this.selectedService?.serviceAddressView?.zipcode
                              ? `${
                                  this.selectedService?.serviceAddressView
                                    ?.zipcode
                                }${
                                  this.selectedService?.serviceAddressView?.city
                                    ? ','
                                    : ''
                                }`
                              : ''}
                            ${this.selectedService?.serviceAddressView?.city ||
                            ''}
                          </div>
                        </div>
                        ${this.selectedService?.serviceAddressView
                          ?.mapImageUrl &&
                        html`<a
                          href="${this.selectedService?.serviceAddressView
                            ?.mapLink}"
                          target="_blank"
                        >
                          <img
                            src="${this.selectedService?.serviceAddressView
                              ?.mapImageUrl}"
                            alt="service"
                        /></a>`}
                      </div>
                    </div>`
                  : ''}
                ${reviews?.length > 0
                  ? html`<div class="serviceReviewData">
                      <div class="reviewsHeading">
                        <div class="serviceHeading">
                          ${this.data?.locale?.Funnel?.Reviews}
                        </div>
                        <div class="reviewAverageHolder">
                          <div class="reviewAverageText">
                            <span>
                              ${this.data?.reviewsView?.averageRate}
                            </span>
                            /5
                          </div>
                          <div class="reviewAverageStars">
                            ${Array.from(Array(5).keys()).map(
                              key =>
                                html` <div class="reviewItemStars">
                                  <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                                      fill="${key + 1 <=
                                      this.data?.reviewsView?.averageRate
                                        ? '#f6c564'
                                        : '#b8c0cc'}"
                                    />
                                  </svg>
                                </div>`
                            )}
                          </div>
                          <div class="reviewCount">
                            (${this.data?.reviewsView?.reviews?.length}
                            ${this.data?.locale?.Funnel?.Reviews})
                          </div>
                        </div>
                      </div>
                      <div class="reviewItems">
                        ${reviews?.map(
                          x => html`<div class="reviewItem">
                            <div class="reviewItemHeading">
                              <div class="reviewItemStarsHolder">
                                ${Array.from(Array(5).keys()).map(
                                  key =>
                                    html` <div class="reviewItemStars">
                                      <svg
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                                          fill="${key + 1 <= x?.rate
                                            ? '#f6c564'
                                            : '#b8c0cc'}"
                                        />
                                      </svg>
                                    </div>`
                                )}
                              </div>
                              <div class="reviewItemAuthor">${x?.fullName}</div>
                              <div class="reviewItemDate">
                                ${x?.createdDate}
                              </div>
                            </div>
                            <div class="reviewItemComment">${x?.comment}</div>
                          </div>`
                        )}
                      </div>
                      ${this.data?.reviewsView?.reviews?.length >
                      this.visibleReviewCount
                        ? html`<div
                            class="reviewLoadMore"
                            @click="${this.onLoadMoreClick}"
                          >
                            ${this.data?.locale?.Funnel?.ShowMore}
                          </div>`
                        : ''}
                    </div>`
                  : ''}
              </div>
              <div>
                <div class="desktopWidgetHolder">
                  <kiwi-launch
                    serviceId=${this.selectedService?.serviceId}
                    brandingSettings=${this.brandingSettings}
                    queryParams=${this.queryParams}
                  ></kiwi-launch>
                </div>
                ${this.data?.briefInfo?.whatsapp ||
                this.data?.briefInfo?.phoneNumber ||
                this.data?.briefInfo?.email
                  ? html`<div class="contactUsHolder">
                      <div class="contactUsTitle">
                        ${this.data?.locale?.Funnel?.HaveQuestions}
                      </div>
                      <div class="contactUsButtonHolder">
                        ${this.data?.briefInfo?.phoneNumber
                          ? html`<a
                              class="contactUsButton"
                              target="_blank"
                              href="tel:${this.data?.briefInfo?.phoneNumber}"
                            >
                              <svg
                                viewBox="0 -256 1792 1792"
                                id="svg3013"
                                version="1.1"
                                width="100%"
                                height="100%"
                              >
                                <g
                                  transform="matrix(1,0,0,-1,159.45763,1293.0169)"
                                  id="g3015"
                                >
                                  <path
                                    d="m 1408,296 q 0,-27 -10,-70.5 Q 1388,182 1377,157 1356,107 1255,51 1161,0 1069,0 1042,0 1016.5,3.5 991,7 959,16 927,25 911.5,30.5 896,36 856,51 816,66 807,69 709,104 632,152 504,231 367.5,367.5 231,504 152,632 104,709 69,807 66,816 51,856 36,896 30.5,911.5 25,927 16,959 7,991 3.5,1016.5 0,1042 0,1069 q 0,92 51,186 56,101 106,122 25,11 68.5,21 43.5,10 70.5,10 14,0 21,-3 18,-6 53,-76 11,-19 30,-54 19,-35 35,-63.5 16,-28.5 31,-53.5 3,-4 17.5,-25 14.5,-21 21.5,-35.5 7,-14.5 7,-28.5 0,-20 -28.5,-50 -28.5,-30 -62,-55 -33.5,-25 -62,-53 -28.5,-28 -28.5,-46 0,-9 5,-22.5 5,-13.5 8.5,-20.5 3.5,-7 14,-24 10.5,-17 11.5,-19 76,-137 174,-235 98,-98 235,-174 2,-1 19,-11.5 17,-10.5 24,-14 7,-3.5 20.5,-8.5 13.5,-5 22.5,-5 18,0 46,28.5 28,28.5 53,62 25,33.5 55,62 30,28.5 50,28.5 14,0 28.5,-7 14.5,-7 35.5,-21.5 21,-14.5 25,-17.5 25,-15 53.5,-31 28.5,-16 63.5,-35 35,-19 54,-30 70,-35 76,-53 3,-7 3,-21 z"
                                    id="path3017"
                                  />
                                </g>
                              </svg>
                              ${this.data?.locale?.Funnel?.ContactUs}
                            </a>`
                          : ''}
                        ${this.data?.briefInfo?.whatsapp
                          ? html`<a
                              class="contactUsButton"
                              target="_blank"
                              href="https://wa.me/${this.data?.briefInfo?.whatsapp?.replace(
                                /[^0-9.]/g,
                                ''
                              )}"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.077 4.928C17.191 3.041 14.683 2.001 12.011 2C6.505 2 2.024 6.479 2.022 11.985C2.021 13.745 2.481 15.463 3.355 16.977L2 22L7.233 20.763C8.692 21.559 10.334 21.978 12.006 21.979H12.01C17.515 21.979 21.996 17.499 21.999 11.994C22.001 9.325 20.963 6.816 19.077 4.928ZM16.898 15.554C16.69 16.137 15.671 16.699 15.213 16.74C14.755 16.782 14.326 16.947 12.218 16.116C9.681 15.116 8.079 12.515 7.955 12.349C7.83 12.182 6.936 10.996 6.936 9.768C6.936 8.54 7.581 7.936 7.81 7.687C8.039 7.437 8.309 7.375 8.476 7.375C8.642 7.375 8.809 7.375 8.954 7.381C9.132 7.388 9.329 7.397 9.516 7.812C9.738 8.306 10.223 9.54 10.285 9.665C10.347 9.79 10.389 9.936 10.306 10.102C10.223 10.268 10.181 10.372 10.057 10.518C9.932 10.664 9.795 10.843 9.683 10.955C9.558 11.079 9.428 11.215 9.573 11.464C9.719 11.714 10.219 12.531 10.961 13.192C11.915 14.042 12.718 14.305 12.968 14.431C13.218 14.556 13.363 14.535 13.509 14.368C13.655 14.202 14.133 13.64 14.299 13.39C14.465 13.14 14.632 13.182 14.861 13.265C15.09 13.348 16.317 13.952 16.566 14.077C16.816 14.202 16.982 14.264 17.044 14.368C17.106 14.471 17.106 14.971 16.898 15.554Z"
                                  fill="#377A4E"
                                />
                              </svg>
                              ${this.data?.locale?.Funnel?.WhatsappUs}
                            </a>`
                          : ''}
                        ${this.data?.briefInfo?.email
                          ? html`<a
                              class="contactUsButton"
                              target="_blank"
                              href="mailto:${this.data?.briefInfo?.email}"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1081_605"
                                  style="mask-type: 'alpha'"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                >
                                  <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1081_605)">
                                  <path
                                    d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM12 13L20 8V6L12 11L4 6V8L12 13Z"
                                    fill="#377A4E"
                                  />
                                </g>
                              </svg>
                              ${this.data?.locale?.Funnel?.EmailUs}
                            </a>`
                          : ''}
                      </div>
                    </div>`
                  : ''}
              </div>
            </div>
          </div>`
        : html` <div class="catalogHolder">
            ${this.data?.description?.length > 11
              ? html`<div class="catalogDetail">
                  ${this.data?.image?.path &&
                  html`<div>
                    <img
                      class="catalogImage"
                      src=${this.data?.image?.path}
                      alt=${this.data?.title}
                    />
                  </div>`}
                  <div class="catalogTextHolder">
                    <div class="catalogStaticTitle">${this.data?.title}</div>
                    <div class="catalogDescription">
                      ${unsafeHTML(this.data?.description)}
                    </div>
                  </div>
                </div>`
              : ''}
            ${Math.ceil(
              (this.data?.services?.length || 0) / this.servicePerPage
            ) > 1
              ? html` <div class="serviceSearchHolder">
                  <svg
                    class="searchIcon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
                      fill="#B8C0CC"
                    />
                  </svg>

                  <input
                    class="serviceSearchInput"
                    type="text"
                    name="searchService"
                    placeholder=${this.data?.locale?.Funnel?.Search}
                    @input=${e => {
                      this.searchText = e.target.value;
                    }}
                    .value="${this.searchText}"
                  />
                </div>`
              : ''}

            <div
              class="catalogServices  ${this.view === 'GRID_VIEW' &&
              'gridViewServices'}"
            >
              ${services.length > 0
                ? services
                    .slice(
                      this.page * this.servicePerPage,
                      this.servicePerPage * (this.page + 1)
                    )
                    .map(
                      service =>
                        html`<div
                          class="catalogServiceItem"
                          id=${service?.serviceId}
                          @click="${() => this.selectService(service)}"
                        >
                          <div class="serviceImageHolder">
                            <img
                              src=${service?.mediaList?.[0]?.thumbnailImage}
                              alt=${service?.title}
                              id=${`${service?.serviceId}-image`}
                            />
                          </div>

                          <div class="catalogServiceItemInfo">
                            <div class="serviceShortInfo">
                              ${service?.nextAvailabilityInfo?.startDate
                                ? html`<div class="nextAvailability">
                                    <span>
                                      ${service?.nextAvailabilityInfo
                                        ?.startDate}
                                    </span>
                                    ${service?.nextAvailabilityInfo
                                      ?.startTime &&
                                    html` <span
                                        class="nextAvailabilityDivider"
                                      ></span>
                                      <span>
                                        ${service?.nextAvailabilityInfo
                                          .startTime}
                                      </span>`}
                                  </div>`
                                : html`<div class="noUpcomingSchedules">
                                    ${this.data?.locale?.Prefunnel
                                      ?.NoAvailableSchedules}
                                  </div>`}

                              <div
                                class="serviceTitle"
                                id=${`${service?.serviceId}-title`}
                              >
                                ${service?.title}
                              </div>
                            </div>
                            <div class="servicePriceInfo">
                              <div
                                class="servicePrice"
                                id=${`${service?.serviceId}-defaultPrice`}
                              >
                                ${service?.defaultPrice === 'FREE'
                                  ? this.data?.locale?.Prefunnel?.Free
                                  : service?.defaultPrice}
                              </div>
                              <div class="button" id="bookNow">
                                ${this.data?.locale?.Prefunnel?.BookNow}
                              </div>
                            </div>
                          </div>
                        </div>`
                    )
                : 'No results found.'}
            </div>
            ${Math.ceil(services?.length / this.servicePerPage) > 1
              ? html`
                  <div class="pagination">
                    <div
                      class="paginationIconHolder  ${this.page === 0 &&
                      'disabled'}"
                      id="paginationPrev"
                      @click="${() => {
                        if (this.page !== 0) {
                          this.page -= 1;
                        }
                      }}"
                    >
                      <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.84175 1.175L5.66675 0L0.666748 5L5.66675 10L6.84175 8.825L3.02508 5L6.84175 1.175Z"
                          fill="#B8C0CC"
                        />
                      </svg>
                    </div>

                    ${Array.from(
                      Array(
                        Math.ceil(services?.length / this.servicePerPage)
                      ).keys()
                    ).map(
                      x =>
                        html` <div
                          class=${`pageItem ${this.page === x && `activePage`}`}
                          id="page-${x + 1}"
                          @click="${() => {
                            this.page = x;
                          }}"
                        >
                          ${x + 1}
                        </div>`
                    )}
                    <div
                      class="paginationIconHolder  ${this.page ===
                        Math.ceil(services?.length / this.servicePerPage) - 1 &&
                      'disabled'}"
                      id="paginationNext"
                      @click="${() => {
                        if (
                          this.page !==
                          Math.ceil(services?.length / this.servicePerPage) - 1
                        ) {
                          this.page += 1;
                        }
                      }}"
                    >
                      <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.33345 0L0.158447 1.175L3.97511 5L0.158447 8.825L1.33345 10L6.33345 5L1.33345 0Z"
                          fill="#191D23"
                        />
                      </svg>
                    </div>
                  </div>
                `
              : ''}
          </div>`}
    </div>`;
  }
}

if (!window.customElements.get('kiwi-catalog')) {
  window.customElements.define('kiwi-catalog', KiwiCatalog);
}
